<template>
  <div
    v-if="userPermissionLoader"
    class="my-64 text-center"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Loading ...
  </div>
  <div
    v-else
    class="min-h-120"
  >
    <div class="flex mb-4 sm:flex-wrap md:flex-wrap lg:flex-wrap xl:flex-wrap 2xl:flex-wrap">
      <div class="flex justify-center w-full flex-grow my-2 items-center">
        <base-select
          v-model="userFilters.searchScope"
          class="w-44 flex items-center text-sm text-left mr-4"
          placeholder="- Select Columns -"
          :options="searchByOptions"
          show-default-option
        />
        <base-input
          v-model="userFilters.searchKeyword"
          type="text"
          container-class="flex mr-4"
        />
        <base-select
          v-model="userFilters.rolename"
          class="w-48 flex items-center text-sm text-left mr-4"
          placeholder="- Select Roles -"
          :options="userRoleOptions.map(role => role.rolename)"
          show-default-option
        />
        <base-button
          class="flex items-center h-7 mr-4"
          variant="btn-primary"
          text="Search"
          @click="handlePageUpdate(1)"
        />
        <base-button
          class="flex items-center h-7 mr-6"
          variant="btn-link"
          text="Clear"
          @click="clearSearch()"
        />
      </div>
      <div class="flex justify-center w-full flex-grow my-2">
        <div
          v-if="totalCount"
          class="mx-4 text-sm p-2 text-primary-red"
        >
          Found <b> {{ totalCount }}</b> Users
        </div>
        <display-limit-selector
          v-model="usersData.limit"
          class="mr-4"
          :options="limitOptions"
        />
        <base-pagination
          :model-value="usersData.page"
          :total-pages="usersData.pageCount"
          @update:modelValue="handlePageUpdate($event)"
        />
      </div>
    </div>
    <!-- users table -->
    <brand-assurance-table
      v-model="usersData.tableData"
      :are-columns-sortable="false"
      root-element-class="mb-10"
    >
      <template
        v-for="(user, uIndex) in usersData.tableData.data"
        :key="'cell-slot' + uIndex + '6'"
        #[generateTableSlotName(uIndex,5)]
      >
        <base-button
          variant="btn-link"
          text="Edit"
          @click="handleEditUser(user)"
        />
      </template>
    </brand-assurance-table>
    <!-- user permissions form modal -->
    <user-permissions-form-modal
      v-model="isPermissionsModalVisible"
      @hide="setPermissionsFormVisibility(false)"
    />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, computed, watch, reactive, ref } from 'vue';
import useBaTable from '@/hooks/baTable.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { useStore } from 'vuex';
import useUserPermissionsForm from '@/components/system-admin-user-permissions-form/userPermissionsForm.js';
import BaseSvg from '@/components/generic-components/BaseSvg.vue';
import { NO_USERS_FOUND, SELECT_COLUMN, WARNING } from '@/constants/alerts';

export default {
    name: 'UserPermissions',

    components: {
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        DisplayLimitSelector: defineAsyncComponent(() => import('@/components/DisplayLimitSelector.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        UserPermissionsFormModal: defineAsyncComponent(() => import('@/components/system-admin-user-permissions-form/UserPermissionsFormModal.vue')),
        BaseSvg
    },

    setup () {
        const { generateTableSlotName } = useBaTable();
        const store = useStore();
        const { userPermissionsForm, isUserPermissionFormUpdated } = useUserPermissionsForm();
        const { notificationsStack } = useToastNotifications();
        const userPermissionLoader = ref(false);
        const totalCount = ref(0);

        // user filers logic
        const userFilters = reactive({
            searchScope: '',
            searchKeyword: '',
            rolename: ''
        });

        // users table logic
        const usersData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Username',
                        key: 'username'
                    },
                    {
                        label: 'Email',
                        key: 'email'
                    },
                    // {
                    //     label: 'login ID',
                    //     key: 'loginId'
                    // },
                    // {
                    //     label: 'User Group',
                    //     key: 'userGroup'
                    // },
                    {
                        label: 'Roles',
                        key: 'roles',
                        style: {
                            width: '24rem'
                        }
                    },
                    {
                        label: 'Status',
                        key: 'status'
                    },
                    {
                        label: 'Actions',
                        key: ''
                    }
                ],
                data: []
            },
            page: 1,
            limit: 50,
            pageCount: 0,
            totalCount: 0
        });
        const handlePageUpdate = (newPage) => {
            usersData.page = newPage;
            handleSearch();
        };
        const limitOptions = ref([50, 100, 200]);
        const searchByOptions = ref([
            'username',
            'email',
            'status',
            'roles'
        ]);

        const userRoleOptions = computed(() => {
            const filteredRolesOpt = fetchedUserRoles?.value?.filter(role => role?.roleId !== 'BAC_FINAL');
            return filteredRolesOpt || [];
        });

        const fetchedUserRoles = computed(() => store.getters['users/getAllRolesList']);
        const roleOptionsLoading = ref(false);
        const fetchRoles = async () => {
            try {
                if ((fetchedUserRoles.value && fetchedUserRoles.value.length > 0) || roleOptionsLoading.value) {
                    return;
                }
                roleOptionsLoading.value = true;
                await store.dispatch('users/fetchAllRoles');
            } catch (err) {
                console.error(err);
            } finally {
                roleOptionsLoading.value = false;
            }
        };

        const searchUser = () => {};
        const clearSearch = () => {
            userFilters.searchScope = '';
            userFilters.searchKeyword = '';
            userFilters.rolename = '';
            fetchUserPermissionsDetails();
        };

        watch(
            () => isUserPermissionFormUpdated.value,
            () => {
                if (isUserPermissionFormUpdated.value) {
                    fetchUserPermissionsDetails();
                }
            }
        );

        watch(
            () => usersData.limit,
            () => {
                usersData.page = 1;
                handleSearch();
            }
        );

        const handleEditUser = (user) => {
            if (!user || !user.email) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: 'Cannot edit the user. Please ensure the user email is valid.'
                });
                return;
            }
            setPermissionsFormVisibility(true, user);
        };

        const fetchedUserPermissionDetails = computed(() => store.getters['users/getUsersList']);
        const handleSearch = () => {
            if (userFilters.searchKeyword && !userFilters.searchScope.length) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SELECT_COLUMN
                });
            } else {
                fetchUserPermissionsDetails(true);
            }
        };
        const fetchUserPermissionsDetails = async (searchField) => {
            if (userPermissionLoader.value) {
                return;
            }
            try {
                userPermissionLoader.value = true;

                const params = {
                    page: usersData.page,
                    limit: usersData.limit
                };
                if (userFilters.searchScope && userFilters.searchKeyword) {
                    params.searchScope = userFilters.searchScope;
                    params.searchKeyword = userFilters.searchKeyword;
                }
                if (userFilters.rolename) params.rolename = userFilters.rolename;

                await store.dispatch('users/fetchUsersList', { params });

                fetchRoles();

                if (!fetchedUserPermissionDetails.value || fetchedUserPermissionDetails.value?.data.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: NO_USERS_FOUND
                    });
                }

                if (fetchedUserPermissionDetails.value.totalCount) {
                    totalCount.value = fetchedUserPermissionDetails.value.totalCount;
                }
                usersData.tableData.data = fetchedUserPermissionDetails.value.data.map(user => {
                    return {
                        ...user,
                        roles: user.roles.join(', ')
                    };
                });

                usersData.pageCount = fetchedUserPermissionDetails.value.pageCount;
                usersData.totalCount = fetchedUserPermissionDetails.value.totalCount;
            } catch (err) {
                console.error(err);
            } finally {
                userPermissionLoader.value = false;
                isUserPermissionFormUpdated.value = false;
            }
        };

        onMounted(() => {
            fetchUserPermissionsDetails();
            // usersData.tableData.data = mockUsersData;
            // usersData.page = 1;
            // usersData.pageCount = 1;
            // usersData.totalCount = 1;
        });

        // user permissions form modal logic
        const isPermissionsModalVisible = ref(false);
        const setPermissionsFormVisibility = (visibility, user) => {
            if (visibility) {
                userPermissionsForm.value.email = user.email;
                userPermissionsForm.value.username = user.username;
            } else {
                userPermissionsForm.value.email = '';
                userPermissionsForm.value.username = '';
            }
            isPermissionsModalVisible.value = visibility;
        };

        return {
            generateTableSlotName,
            // user filters
            userFilters,
            userPermissionLoader,
            // user tables
            usersData,
            handlePageUpdate,
            limitOptions,
            searchByOptions,
            userRoleOptions,
            searchUser,
            clearSearch,
            handleEditUser,
            // user permissions form modal
            isPermissionsModalVisible,
            setPermissionsFormVisibility,
            handleSearch,
            fetchUserPermissionsDetails,
            totalCount
        };
    }
};
</script>
